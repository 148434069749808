// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

var $ = require('jquery');
$(function() {

});

// var userFeed = new Instafeed({
// 	get: 'user',
// 	userId: '247950452',
// 	accessToken: '4605720843954f77ac542cba77565eae'
// });
// userFeed.run();
// var feed = new Instafeed({
// 	clientId: '6a0b715802e34999b1803e2101b89a94',
// 	accessToken: 'a926450c2238483aad68d4c315485f5b',
// 	limit: 20,
// 	sortBy: 'most-liked',
// 	template: '<a href="{{link}}"><img src="{{image}}" /></a>'
// });
// feed.run();

